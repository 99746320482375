import React from 'react'

const ProjectSidebar = ({ listing }) => {
    return (
        <div>
            <div className="courses-details-info">
                <ul className="info">
                    <li className="price">
                        <div className="d-flex justify-content-between align-items-center">
                            <span><i className="flaticon-analysis"></i>Sq Ft</span>
                            {listing.sqftTotal}
                        </div>
                    </li>
                    <li>
                        <div className="d-flex justify-content-between align-items-center">
                            <span><i className="flaticon-right-1"></i>Bedroom</span>
                            {listing.bed}
                        </div>
                    </li>
                    <li>
                        <div className="d-flex justify-content-between align-items-center">
                            <span><i className="flaticon-left-1"></i>Bathroom</span>
                            {listing.bath}
                        </div>
                    </li>
                    <li>
                        <div className="d-flex justify-content-between align-items-center">
                            <span><i className="flaticon-house"></i>Property Type</span>
                            {listing.propertyType}
                        </div>
                    </li>
                </ul>
            <div className="events-details-location">
                <iframe src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyDr7gRsDPCn7v3tZ1jlD1_oOyiu9dg9M6s&zoom=12&q=" + listing.acurate_address}></iframe>
            </div>
            </div>
        </div>
    )
}

export default ProjectSidebar