import React from 'react'
import ProjectSidebar from './ProjectSidebar'
import {Link} from 'gatsby'
import course1 from '../../assets/images/courses/course1.jpg'

import Loadable from '@loadable/component'
const ModalVideo = Loadable(() => import('react-modal-video'))

const ProjectDetailsContent = ({ listing }) => {
    const [isOpen, setIsOpen] = React.useState(true);
    const openModal = () => {
        setIsOpen(!isOpen);
    }
    
    return (
        <React.Fragment>
            <ModalVideo 
                channel='youtube' 
                isOpen={!isOpen} 
                videoId={listing.video}
                onClose={() => setIsOpen(!isOpen)} 
            /> 
            <section className="events-details-area pb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-12">
                            <div className="events-details-header">
                                <ul>
                                    <li><i className='bx bx-calendar'></i>{listing.date}</li>
                                    <li><i className='bx bx-map'></i>{listing.address}</li>
                                </ul>
                            </div>

                            <div className="events-details-desc">
                                <h3>About The Property</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                            </div>

                            <div className="courses-details-info">
                                <div className="image">
                                    <img src={listing.images[0]} alt="course1" />
                                    
                                    <Link 
                                        to="#" 
                                        onClick={e => {e.preventDefault(); openModal()}} 
                                        className="link-btn popup-youtube"
                                    >
                                    </Link>

                                    <div className="content">
                                        <i className="flaticon-google-play"></i>
                                        <span>Property Preview</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-12">
                            <ProjectSidebar listing={listing}/>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default ProjectDetailsContent