const RoomType = {
    MASTER_BEDROOM: "Master Bedroom",
    MASTER_BATHROOM: "Master Bathroom",
    GUEST_BEDROOM: "Guest Bedroom",
    GUEST_BATHROOM: "Guest Bathroom",
    LIVING_ROOM: "Living Room",
    COMMON_AREA: "Common",
    FAMILY_ROOM: "Family Room",
    DINNING_ROOM: "Dinning Room",
    KITCHEN: "Kitchen",
    DINNING_AREA: "Dinning Area",
    OFFICE_ROOM: "Office Room",
    OFFICE_AREA: "Office Area",
    LAUNDRY: "Laundry",
    BACKYARD: "Backyard",
    FRONTYARD: "Frontyard",
    LOFT: "Loft",
}

Object.freeze(RoomType);

export default RoomType
