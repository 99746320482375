import React from 'react'
import Layout from "../components/App/Layout"
import NavbarStaging from "../components/App/NavbarStaging"
import Footer from "../components/App/Footer"
import {Link} from 'gatsby'
import Lightbox from 'react-image-lightbox'
import ProjectDetailsContent from '../components/Projects/ProjectDetailsContent'
import IMAGES from "../utils/images"

const ProjectDetails = (props) => {
    const elements = []

    var property = props.pageContext.listing.address
    var images = IMAGES.filter(function(image) {
        return image.property === property;
    });

    for(let i = 0; i < images.length; i++){ 
        const image = images[i]
        elements.push(
            <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="single-gallery-item">
                    <Link 
                        to="#"
                        onClick={e => {
                            e.preventDefault(); 
                            setIsOpenImage(true); 
                            setPhotoIndex(i);}
                        }
                    >
                        <img src={image.url} alt="event" />
                    </Link>
                </div>
            </div>
        )
    }
    const [photoIndex, setPhotoIndex] = React.useState(0);
    const [isOpenImage, setIsOpenImage] = React.useState(false);

    return (
        <Layout>
            <NavbarStaging />
            <ProjectDetailsContent listing={props.pageContext.listing}  />

            <div className="gallery-area pt-50 pb-70">
                <div className="container">
                    <div className="row">
                        { elements }
                    </div>
                </div>

                {/* Lightbox */}
                {isOpenImage && (
                    <Lightbox
                        mainSrc={images[photoIndex].url}
                        nextSrc={images[(photoIndex + 1) % images.length].url}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length].url}
                        onCloseRequest={() => setIsOpenImage(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + images.length - 1) % images.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % images.length)
                        }
                    />
                )}
            </div>
            
            <Footer />
        </Layout>
    );
}

export default ProjectDetails