import RoomType from './RoomType'

const IMAGES = [
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571022/4536%20Darcelle%20Dr/common_1_3_sm39r8.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.COMMON_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571021/4536%20Darcelle%20Dr/common_1_1_uhbmmx.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.COMMON_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571021/4536%20Darcelle%20Dr/common_1_2_ddrdda.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.COMMON_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571532/4536%20Darcelle%20Dr/family_room_1_8_pule9k.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.FAMILY_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571531/4536%20Darcelle%20Dr/family_room_1_9_pm03n3.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.FAMILY_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571530/4536%20Darcelle%20Dr/family_room_1_7_njj1pv.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.FAMILY_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571529/4536%20Darcelle%20Dr/family_room_1_1_alz2hy.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.FAMILY_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571527/4536%20Darcelle%20Dr/family_room_1_6_vbez5m.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.FAMILY_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571526/4536%20Darcelle%20Dr/family_room_1_2_xepev5.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.FAMILY_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571526/4536%20Darcelle%20Dr/family_room_1_5_t0pufg.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.FAMILY_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571525/4536%20Darcelle%20Dr/family_room_1_3_i9jj3w.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.FAMILY_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571521/4536%20Darcelle%20Dr/family_room_1_4_cnoh9y.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.FAMILY_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571639/4536%20Darcelle%20Dr/dinning_area_1_2_rttkhb.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.DINNING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571641/4536%20Darcelle%20Dr/dinning_area_1_1_rdmrie.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.DINNING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571705/4536%20Darcelle%20Dr/living_room_1_1_q4vwjr.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571706/4536%20Darcelle%20Dr/living_room_1_4_kvwyxs.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571708/4536%20Darcelle%20Dr/living_room_1_3_o76zug.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571708/4536%20Darcelle%20Dr/living_room_1_2_dfwj90.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571774/4536%20Darcelle%20Dr/kitchen_1_2_jcjjnh.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571775/4536%20Darcelle%20Dr/kitchen_1_4_e3zgw1.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571777/4536%20Darcelle%20Dr/kitchen_1_1_okwdoh.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571777/4536%20Darcelle%20Dr/kitchen_1_3_u3inn3.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571847/4536%20Darcelle%20Dr/master_bedroom_1_4_tpyhzr.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571848/4536%20Darcelle%20Dr/master_bedroom_1_2_i1m16v.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571849/4536%20Darcelle%20Dr/master_bedroom_1_1_xelbwv.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571849/4536%20Darcelle%20Dr/master_bedroom_1_3_vvlwaw.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571887/4536%20Darcelle%20Dr/laundry_room_1_1_k7nmbe.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.LAUNDRY,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656571937/4536%20Darcelle%20Dr/bathroom_1_1_b3uemj.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.GUEST_BATHROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656572015/4536%20Darcelle%20Dr/guest_bathroom_1_1_fkl14f.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.GUEST_BATHROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656572054/4536%20Darcelle%20Dr/guest_bedroom_1_1_ajf7zl.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 1
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656572055/4536%20Darcelle%20Dr/guest_bedroom_1_2_fhyuup.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 1
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656572102/4536%20Darcelle%20Dr/guest_bedroom_2_2_j1c0c1.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 2
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656572103/4536%20Darcelle%20Dr/guest_bedroom_2_1_esah7o.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 2
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656572107/4536%20Darcelle%20Dr/guest_bedroom_2_3_paaa6q.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 2
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656572144/4536%20Darcelle%20Dr/guest_bedroom_3_1_kvtwya.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 3
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656572144/4536%20Darcelle%20Dr/guest_bedroom_3_2_dao4q0.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 3
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656572146/4536%20Darcelle%20Dr/guest_bedroom_3_3_ktne6u.jpg",
        property: "4536 Darcelle Dr",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 3
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656631847/4264%20Healdsburgh%20Way/common_1_3_ylyd9q.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.COMMON_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656631846/4264%20Healdsburgh%20Way/common_1_1_srfhh3.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.COMMON_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656631845/4264%20Healdsburgh%20Way/common_1_2_nucqgt.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.COMMON_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656631954/4264%20Healdsburgh%20Way/dinning_area_1_1_xjk47l.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.DINNING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656631952/4264%20Healdsburgh%20Way/dinning_area_1_2_ba8vtn.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.DINNING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656632056/4264%20Healdsburgh%20Way/master_bedroom_1_1_bk3xrs.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656632058/4264%20Healdsburgh%20Way/master_bedroom_1_2_wedavw.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656632058/4264%20Healdsburgh%20Way/master_bedroom_1_3_wtxku0.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656632127/4264%20Healdsburgh%20Way/master_bathroom_1_1_acxw0l.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.MASTER_BATHROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656632125/4264%20Healdsburgh%20Way/master_bathroom_1_2_yntkyg.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.MASTER_BATHROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656632183/4264%20Healdsburgh%20Way/office_room_1_1_khyfty.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.OFFICE_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656632185/4264%20Healdsburgh%20Way/office_room_1_2_ezlcwi.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.OFFICE_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656632222/4264%20Healdsburgh%20Way/loft_1_2_cw5sqa.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.LOFT,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656632225/4264%20Healdsburgh%20Way/loft_1_1_napk1x.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.LOFT,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656632228/4264%20Healdsburgh%20Way/loft_1_3_qxrcg5.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.LOFT,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656632284/4264%20Healdsburgh%20Way/living_room_1_1_yv44e2.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656632283/4264%20Healdsburgh%20Way/living_room_1_3_ej1jok.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656632279/4264%20Healdsburgh%20Way/living_room_1_2_ngxfrw.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656632334/4264%20Healdsburgh%20Way/laundry_room_1_1_ctu8mi.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.LAUNDRY,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656632375/4264%20Healdsburgh%20Way/kitchen_1_1_m3d6y5.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656632376/4264%20Healdsburgh%20Way/kitchen_1_3_nmwacg.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656632376/4264%20Healdsburgh%20Way/kitchen_1_2_cqxcqi.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656632430/4264%20Healdsburgh%20Way/guest_bedroom_2_2_cbslgh.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 1
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656632431/4264%20Healdsburgh%20Way/guest_bedroom_2_1_uowb1p.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 1
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656632488/4264%20Healdsburgh%20Way/guest_bedroom_1_1_v1v5wl.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 2
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656632488/4264%20Healdsburgh%20Way/guest_bedroom_1_3_ooicda.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 2
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656632485/4264%20Healdsburgh%20Way/guest_bedroom_1_2_nlgtrv.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 2
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656632540/4264%20Healdsburgh%20Way/guest_bathroom_1_2_fs2q4v.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.GUEST_BATHROOM,
        seq: 1
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656632541/4264%20Healdsburgh%20Way/guest_bathroom_1_1_bcfmeo.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.GUEST_BATHROOM,
        seq: 2
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656632682/4264%20Healdsburgh%20Way/garden_1_1_hefmfv.jpg",
        property: "4264 Healdsburg Way",
        roomType: RoomType.BACKYARD,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636354/2045%20Castillejo%20Way/garden_1_2_v2scnw.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.BACKYARD,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636352/2045%20Castillejo%20Way/garden_1_1_qjynqt.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.BACKYARD,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636352/2045%20Castillejo%20Way/garden_1_3_hujhfg.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.BACKYARD,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636347/2045%20Castillejo%20Way/garden_1_4_xuchqz.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.BACKYARD,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636407/2045%20Castillejo%20Way/common_1_1_viabx5.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.COMMON_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636465/2045%20Castillejo%20Way/family_room_1_2_qpgowe.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.FAMILY_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636463/2045%20Castillejo%20Way/family_room_1_3_eurbzh.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.FAMILY_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636460/2045%20Castillejo%20Way/family_room_1_1_wvv6ol.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.FAMILY_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636507/2045%20Castillejo%20Way/dinning_room_1_1_yqyyu5.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.DINNING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636502/2045%20Castillejo%20Way/dinning_area_1_1_ui2hhn.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.DINNING_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636552/2045%20Castillejo%20Way/living_room_1_1_wcxjrx.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636552/2045%20Castillejo%20Way/living_room_1_3_oyayw9.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636552/2045%20Castillejo%20Way/living_room_1_2_exuadm.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636619/2045%20Castillejo%20Way/master_bedroom_1_1_hacaj3.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636619/2045%20Castillejo%20Way/master_bedroom_1_3_u9olnh.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636616/2045%20Castillejo%20Way/master_bedroom_1_2_x9yx0u.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636675/2045%20Castillejo%20Way/master_bathroom_1_1_votbfv.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636675/2045%20Castillejo%20Way/master_bathroom_1_2_sowr4e.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636718/2045%20Castillejo%20Way/office_room_1_2_dc5kbb.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.OFFICE_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636719/2045%20Castillejo%20Way/office_room_1_1_y6hsbg.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.OFFICE_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636761/2045%20Castillejo%20Way/kitchen_1_2_ssqile.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636760/2045%20Castillejo%20Way/kitchen_1_1_sb1iow.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636759/2045%20Castillejo%20Way/kitchen_1_3_p3hw3m.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636755/2045%20Castillejo%20Way/laundry_a6ai0b.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.LAUNDRY,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636852/2045%20Castillejo%20Way/guest_bedroom_1_2_z9fz1t.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 1,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636856/2045%20Castillejo%20Way/guest_bedroom_1_1_vgg9fj.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 1,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636856/2045%20Castillejo%20Way/guest_bedroom_3_1_gompts.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 2,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636854/2045%20Castillejo%20Way/guest_bedroom_2_1_bwwr9g.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 3,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636948/2045%20Castillejo%20Way/guest_bathroom_1_1_wgw8ak.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.GUEST_BATHROOM,
        seq: 1,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636949/2045%20Castillejo%20Way/guest_bathroom_2_1_j6ldxl.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.GUEST_BATHROOM,
        seq: 2,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656636950/2045%20Castillejo%20Way/guest_bathroom_3_1_bxyu0u.jpg",
        property: "2045 Castillejo Way",
        roomType: RoomType.GUEST_BATHROOM,
        seq: 3,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656654994/3564%20Touriga%20Dr/living_room_1_1_mipmaj.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656654992/3564%20Touriga%20Dr/living_room_1_4_iqewef.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656654991/3564%20Touriga%20Dr/living_room_1_3_qzaqgz.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656654985/3564%20Touriga%20Dr/living_room_1_2_j4b5uc.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656654984/3564%20Touriga%20Dr/living_room_1_5_k6jrda.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656655157/3564%20Touriga%20Dr/dinning_room_1_2_vgpcln.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.DINNING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656655156/3564%20Touriga%20Dr/dinning_room_1_1_wjlbak.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.DINNING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656655156/3564%20Touriga%20Dr/dinning_area_1_1_oguxpu.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.DINNING_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656655210/3564%20Touriga%20Dr/family_room_1_2_scieaq.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.DINNING_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656655209/3564%20Touriga%20Dr/family_room_1_1_oratjv.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.FAMILY_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656655286/3564%20Touriga%20Dr/master_bedroom_1_2_vk0pcp.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656655286/3564%20Touriga%20Dr/master_bedroom_1_3_b0rrff.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656655284/3564%20Touriga%20Dr/master_bedroom_1_4_iqyuto.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656655283/3564%20Touriga%20Dr/master_bedroom_1_1_dcaspw.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656655277/3564%20Touriga%20Dr/master_bathroom_1_1_xs3pkh.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.MASTER_BATHROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656655269/3564%20Touriga%20Dr/master_bathroom_1_2_rvu2wa.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.MASTER_BATHROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656655402/3564%20Touriga%20Dr/guest_bedroom_1_1_okwy97.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 3
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656655404/3564%20Touriga%20Dr/guest_bedroom_3_1_vuwdwk.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 2
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656655399/3564%20Touriga%20Dr/guest_bedroom_3_2_lcbmdp.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 2
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656655399/3564%20Touriga%20Dr/guest_bedroom_3_3_ohwgaw.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 2
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656655403/3564%20Touriga%20Dr/guest_bedroom_2_2_e63nyo.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 3
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656655401/3564%20Touriga%20Dr/guest_bedroom_2_1_ihmnpd.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 3
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656655569/3564%20Touriga%20Dr/guest_bedroom_4_2_lhr0mx.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 4
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656655567/3564%20Touriga%20Dr/guest_bedroom_4_1_bef1nj.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 4
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656655566/3564%20Touriga%20Dr/guest_bedroom_4_3_jjibmk.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 4
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656655570/3564%20Touriga%20Dr/kitchen_1_1_l0qsuw.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656655566/3564%20Touriga%20Dr/kitchen_1_2_ekcyk7.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656655560/3564%20Touriga%20Dr/kitchen_1_3_iieymc.jpg",
        property: "3564 Touriga Dr",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656656675/752%20Menker%20Ave/living_room_1_2_lhzgg6.jpg",
        property: "752 Menker Ave",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656656674/752%20Menker%20Ave/living_room_1_1_f4wmuw.jpg",
        property: "752 Menker Ave",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656656673/752%20Menker%20Ave/living_room_1_3_je14w2.jpg",
        property: "752 Menker Ave",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656656670/752%20Menker%20Ave/living_room_1_4_r68u8x.jpg",
        property: "752 Menker Ave",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656656771/752%20Menker%20Ave/dinning_room_1_1_fhtt9k.jpg",
        property: "752 Menker Ave",
        roomType: RoomType.DINNING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656656769/752%20Menker%20Ave/dinning_room_1_3_ixw4xm.jpg",
        property: "752 Menker Ave",
        roomType: RoomType.DINNING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656656769/752%20Menker%20Ave/dinning_room_1_2_tswhd4.jpg",
        property: "752 Menker Ave",
        roomType: RoomType.DINNING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656656771/752%20Menker%20Ave/dinning_room_1_1_fhtt9k.jpg",
        property: "752 Menker Ave",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 1
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656656820/752%20Menker%20Ave/guest_bedroom_2_1_smi8kj.jpg",
        property: "752 Menker Ave",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 2
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656656814/752%20Menker%20Ave/guest_bedroom_2_2_rhehfj.jpg",
        property: "752 Menker Ave",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 2
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656656826/752%20Menker%20Ave/guest_bedroom_3_2_z8ugn1.jpg",
        property: "752 Menker Ave",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 3
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656656824/752%20Menker%20Ave/guest_bedroom_3_1_diju41.jpg",
        property: "752 Menker Ave",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 3
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656656823/752%20Menker%20Ave/guest_bedroom_3_3_ya8vhw.jpg",
        property: "752 Menker Ave",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 3
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656656951/752%20Menker%20Ave/kitchen_1_1_nd35tn.jpg",
        property: "752 Menker Ave",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656656952/752%20Menker%20Ave/kitchen_1_2_mqvlew.jpg",
        property: "752 Menker Ave",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656656953/752%20Menker%20Ave/kitchen_1_4_ebedio.jpg",
        property: "752 Menker Ave",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656656953/752%20Menker%20Ave/kitchen_1_3_i6nstt.jpg",
        property: "752 Menker Ave",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656657011/752%20Menker%20Ave/master_bedroom_1_3_c1l07o.jpg",
        property: "752 Menker Ave",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656657018/752%20Menker%20Ave/master_bedroom_1_1_bvua42.jpg",
        property: "752 Menker Ave",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656657018/752%20Menker%20Ave/master_bedroom_1_2_tverzd.jpg",
        property: "752 Menker Ave",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656657018/752%20Menker%20Ave/master_bathroom_1_1_gxljjr.jpg",
        property: "752 Menker Ave",
        roomType: RoomType.MASTER_BATHROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656658274/6262%20Mountford%20Dr/common_1_1_rkw2r4.jpg",
        property: "6262 Mountford Dr",
        roomType: RoomType.COMMON_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656658270/6262%20Mountford%20Dr/common_1_2_racx05.jpg",
        property: "6262 Mountford Dr",
        roomType: RoomType.COMMON_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656658335/6262%20Mountford%20Dr/family_room_1_2_cy6rft.jpg",
        property: "6262 Mountford Dr",
        roomType: RoomType.FAMILY_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656658334/6262%20Mountford%20Dr/family_room_1_1_m1ux9n.jpg",
        property: "6262 Mountford Dr",
        roomType: RoomType.FAMILY_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656658334/6262%20Mountford%20Dr/dinning_room_1_2_q5vkzc.jpg",
        property: "6262 Mountford Dr",
        roomType: RoomType.DINNING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656658328/6262%20Mountford%20Dr/dinning_room_1_1_pmijmh.jpg",
        property: "6262 Mountford Dr",
        roomType: RoomType.DINNING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656658426/6262%20Mountford%20Dr/guest_bedroom_1_3_iqnjwv.jpg",
        property: "6262 Mountford Dr",
        roomType: RoomType.GUEST_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656658429/6262%20Mountford%20Dr/guest_bedroom_1_2_ozf4qv.jpg",
        property: "6262 Mountford Dr",
        roomType: RoomType.GUEST_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656658428/6262%20Mountford%20Dr/guest_bedroom_1_1_xel9tx.jpg",
        property: "6262 Mountford Dr",
        roomType: RoomType.GUEST_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656658428/6262%20Mountford%20Dr/guest_bedroom_2_1_cqhuen.jpg",
        property: "6262 Mountford Dr",
        roomType: RoomType.GUEST_BATHROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656658430/6262%20Mountford%20Dr/guest_bathroom_1_1_cz6rms.jpg",
        property: "6262 Mountford Dr",
        roomType: RoomType.GUEST_BATHROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656658525/6262%20Mountford%20Dr/living_room_1_3_jksazh.jpg",
        property: "6262 Mountford Dr",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656658525/6262%20Mountford%20Dr/living_room_1_4_uul9kt.jpg",
        property: "6262 Mountford Dr",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656658521/6262%20Mountford%20Dr/living_room_1_2_ehqf13.jpg",
        property: "6262 Mountford Dr",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656658521/6262%20Mountford%20Dr/living_room_1_1_co5yxp.jpg",
        property: "6262 Mountford Dr",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656658516/6262%20Mountford%20Dr/kitchen_1_1_tt5su9.jpg",
        property: "6262 Mountford Dr",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656658511/6262%20Mountford%20Dr/kitchen_1_2_yoebes.jpg",
        property: "6262 Mountford Dr",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656658513/6262%20Mountford%20Dr/laundry_ky5vfw.jpg",
        property: "6262 Mountford Dr",
        roomType: RoomType.LAUNDRY,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656658634/6262%20Mountford%20Dr/master_bedroom_1_2_m53tcn.jpg",
        property: "6262 Mountford Dr",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656658638/6262%20Mountford%20Dr/master_bedroom_1_1_t0c25i.jpg",
        property: "6262 Mountford Dr",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656658638/6262%20Mountford%20Dr/master_bedroom_1_4_wsmj0i.jpg",
        property: "6262 Mountford Dr",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656658638/6262%20Mountford%20Dr/master_bedroom_1_3_rmg3zq.jpg",
        property: "6262 Mountford Dr",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656658634/6262%20Mountford%20Dr/office_area_1_1_rdvpj0.jpg",
        property: "6262 Mountford Dr",
        roomType: RoomType.OFFICE_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656660759/3462%20Ironwood%20Dr/common_1_2_anjmz3.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.COMMON_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656660758/3462%20Ironwood%20Dr/common_1_3_d4hklz.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.COMMON_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656660758/3462%20Ironwood%20Dr/common_1_4_jtyuiy.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.COMMON_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656660755/3462%20Ironwood%20Dr/common_1_1_r82uah.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.COMMON_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656660822/3462%20Ironwood%20Dr/entry_1__jvm0gp.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.COMMON_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656660823/3462%20Ironwood%20Dr/dinning_area_1_1_qbzgu6.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.DINNING_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656660823/3462%20Ironwood%20Dr/family_room_1_2_f3ux0h.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.FAMILY_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656660822/3462%20Ironwood%20Dr/family_room_1_1_pn4gql.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.FAMILY_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656660884/3462%20Ironwood%20Dr/living_room_1_1_k1tewt.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656660885/3462%20Ironwood%20Dr/living_room_1_2_crptrz.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656660920/3462%20Ironwood%20Dr/kitchen_1_1_dnvt1k.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656660920/3462%20Ironwood%20Dr/kitchen_1_2_rnukan.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656660921/3462%20Ironwood%20Dr/kitchen_1_4_ihek4w.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656660923/3462%20Ironwood%20Dr/kitchen_1_3_aswm2o.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656660924/3462%20Ironwood%20Dr/laundry_1_1_ivlfgr.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.LAUNDRY,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656661018/3462%20Ironwood%20Dr/guest_bedroom_2_1_haecja.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 1
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656661020/3462%20Ironwood%20Dr/guest_bedroom_1_3_wj53qc.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 2
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656661020/3462%20Ironwood%20Dr/guest_bedroom_1_2_h51cwo.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 2
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656661022/3462%20Ironwood%20Dr/guest_bedroom_1_1_kvxsve.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 3
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656661021/3462%20Ironwood%20Dr/guest_bedroom_3_1_z10i1g.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 3
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656661023/3462%20Ironwood%20Dr/guest_bedroom_3_2_j1j32j.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 4
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656661022/3462%20Ironwood%20Dr/guest_bathroom_1_1_bk3liv.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.GUEST_BATHROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656661272/3462%20Ironwood%20Dr/master_bedroom_1_3_d6eucz.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656661272/3462%20Ironwood%20Dr/master_bedroom_1_2_ozwuxu.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656661264/3462%20Ironwood%20Dr/master_bedroom_1_1_eu99ah.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656661270/3462%20Ironwood%20Dr/master_bathroom_1_2_ekcxdg.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.MASTER_BATHROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656661268/3462%20Ironwood%20Dr/master_bathroom_1_3_mbyobe.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.MASTER_BATHROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656661268/3462%20Ironwood%20Dr/master_bathroom_1_5_vgq0fg.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.MASTER_BATHROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656661267/3462%20Ironwood%20Dr/master_bathroom_1_1_zkm2nz.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.MASTER_BATHROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656661266/3462%20Ironwood%20Dr/master_bathroom_1_4_ufvhmd.jpg",
        property: "3462 Ironwood Dr",
        roomType: RoomType.MASTER_BATHROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725631/1518%20Honey%20Suckle%20Ct/backyard_1_4_iqowfd.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.BACKYARD,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725631/1518%20Honey%20Suckle%20Ct/backyard_1_6_hu77rt.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.BACKYARD,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725631/1518%20Honey%20Suckle%20Ct/backyard_1_1_btz9jc.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.BACKYARD,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725629/1518%20Honey%20Suckle%20Ct/backyard_1_2_lewmyz.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.BACKYARD,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725628/1518%20Honey%20Suckle%20Ct/backyard_1_5_qbcohk.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.BACKYARD,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725660/1518%20Honey%20Suckle%20Ct/backyard_1_11_bm5chv.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.BACKYARD,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725659/1518%20Honey%20Suckle%20Ct/backyard_1_8_uanwgw.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.BACKYARD,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725657/1518%20Honey%20Suckle%20Ct/backyard_1_7_lswnsn.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.BACKYARD,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725657/1518%20Honey%20Suckle%20Ct/backyard_1_10_ee4huw.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.BACKYARD,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725655/1518%20Honey%20Suckle%20Ct/backyard_1_9_cajwqx.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.BACKYARD,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725633/1518%20Honey%20Suckle%20Ct/backyard_1_3_qm0isp.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.BACKYARD,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725668/1518%20Honey%20Suckle%20Ct/backyard_1_13_ejm3w4.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.BACKYARD,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725661/1518%20Honey%20Suckle%20Ct/backyard_1_12_s6izmi.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.BACKYARD,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725667/1518%20Honey%20Suckle%20Ct/backyard_1_14_esf9he.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.BACKYARD,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725875/1518%20Honey%20Suckle%20Ct/common_1_2_tip8ka.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.COMMON_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725872/1518%20Honey%20Suckle%20Ct/common_1_3_jrxtol.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.COMMON_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725867/1518%20Honey%20Suckle%20Ct/common_1_1_oo9lgy.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.COMMON_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725870/1518%20Honey%20Suckle%20Ct/dinning_room_1_1_kewufd.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.DINNING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725878/1518%20Honey%20Suckle%20Ct/dinning_room_1_2_jigexw.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.DINNING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725878/1518%20Honey%20Suckle%20Ct/family_room_1_1_e0hlwn.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.FAMILY_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725879/1518%20Honey%20Suckle%20Ct/family_room_1_2_aixlbb.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.FAMILY_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725988/1518%20Honey%20Suckle%20Ct/frontyard_1_2_avxq3y.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.FRONTYARD,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725987/1518%20Honey%20Suckle%20Ct/frontyard_1_4_yujwtu.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.FRONTYARD,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725986/1518%20Honey%20Suckle%20Ct/frontyard_1_1_wzaxuk.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.FRONTYARD,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656725986/1518%20Honey%20Suckle%20Ct/frontyard_1_3_ixzuna.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.FRONTYARD,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656726070/1518%20Honey%20Suckle%20Ct/master_bedroom_1_3_gaaj0s.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656726062/1518%20Honey%20Suckle%20Ct/master_bedroom_1_1_ntbmts.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656726059/1518%20Honey%20Suckle%20Ct/master_bedroom_1_2_k5sp0z.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656726060/1518%20Honey%20Suckle%20Ct/office_room_1_1_uei39c.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.OFFICE_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656726066/1518%20Honey%20Suckle%20Ct/office_room_2_1_k9eihe.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.OFFICE_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656726063/1518%20Honey%20Suckle%20Ct/master_bathroom_1_1_ci4x2y.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.MASTER_BATHROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656726062/1518%20Honey%20Suckle%20Ct/master_bathroom_1_2_lnh393.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.MASTER_BATHROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656726185/1518%20Honey%20Suckle%20Ct/living_room_1_2_pi6zsg.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656726185/1518%20Honey%20Suckle%20Ct/living_room_1_3_opf04d.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656726185/1518%20Honey%20Suckle%20Ct/living_room_1_1_k5b7uj.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656726180/1518%20Honey%20Suckle%20Ct/living_room_1_4_ww9gyi.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656790136/1518%20Honey%20Suckle%20Ct/kitchen_1_1_phcpm3.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656790134/1518%20Honey%20Suckle%20Ct/kitchen_1_6_tldazt.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656790134/1518%20Honey%20Suckle%20Ct/kitchen_1_3_ccqzfs.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656790131/1518%20Honey%20Suckle%20Ct/kitchen_1_4_qzycxd.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656790130/1518%20Honey%20Suckle%20Ct/kitchen_1_5_ic8oxz.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656790125/1518%20Honey%20Suckle%20Ct/kitchen_1_2_gq9gkt.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656790135/1518%20Honey%20Suckle%20Ct/laundry_1_1_z602td.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.LAUNDRY,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656790260/1518%20Honey%20Suckle%20Ct/guest_bedroom_3_2_qcauzs.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 1
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656790258/1518%20Honey%20Suckle%20Ct/guest_bedroom_3_1_easxvb.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 1
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656790257/1518%20Honey%20Suckle%20Ct/guest_bedroom_2_1_tqzhxq.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 2
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656790256/1518%20Honey%20Suckle%20Ct/guest_bedroom_2_2_uhjh5r.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 2
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656790248/1518%20Honey%20Suckle%20Ct/guest_bedroom_1_2_azsfgv.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 3
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656790243/1518%20Honey%20Suckle%20Ct/guest_bedroom_1_1_nu7qda.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 3
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656790252/1518%20Honey%20Suckle%20Ct/guest_bathroom_4_1_mmuvek.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.GUEST_BATHROOM,
        seq: 1
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656790248/1518%20Honey%20Suckle%20Ct/guest_bathroom_2_1_pcpr6e.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.GUEST_BATHROOM,
        seq: 2
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656790247/1518%20Honey%20Suckle%20Ct/guest_bathroom_1_1_ky9wfq.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.GUEST_BATHROOM,
        seq: 3
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656790246/1518%20Honey%20Suckle%20Ct/guest_bathroom_3_1_r6dqdf.jpg",
        property: "1518 Honey Suckle Ct",
        roomType: RoomType.GUEST_BATHROOM,
        seq: 4
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819506/1853%20Midnight%20Cir/common_1_2_yuf5qt.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.COMMON_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819506/1853%20Midnight%20Cir/common_1_3_zu5hu8.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.COMMON_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819512/1853%20Midnight%20Cir/common_1_4_tcqtr4.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.COMMON_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819511/1853%20Midnight%20Cir/common_1_1_p1v1p4.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.COMMON_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819511/1853%20Midnight%20Cir/common_1_6_devxxr.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.COMMON_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819507/1853%20Midnight%20Cir/common_1_5_fcouyz.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.COMMON_AREA,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819578/1853%20Midnight%20Cir/living_room_1_5_lqzdu2.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819576/1853%20Midnight%20Cir/living_room_1_4_nbmdax.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819574/1853%20Midnight%20Cir/living_room_1_2_nx2u7n.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819574/1853%20Midnight%20Cir/living_room_1_3_kdxsuq.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819571/1853%20Midnight%20Cir/living_room_1_1_z9dr5f.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.LIVING_ROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819629/1853%20Midnight%20Cir/master_bedroom_1_1_jcssk4.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819627/1853%20Midnight%20Cir/master_bedroom_1_4_elfe97.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819627/1853%20Midnight%20Cir/master_bedroom_1_2_ifpcff.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819622/1853%20Midnight%20Cir/master_bedroom_1_3_wvy8ka.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.MASTER_BEDROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819677/1853%20Midnight%20Cir/kitchen_1_2_sxhn8t.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819676/1853%20Midnight%20Cir/kitchen_1_4_yzk8kq.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819674/1853%20Midnight%20Cir/kitchen_1_1_mvncwu.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819673/1853%20Midnight%20Cir/kitchen_1_3_fxiu3h.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.KITCHEN,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819734/1853%20Midnight%20Cir/guest_bedroom_1_1_jub1fw.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 1
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819725/1853%20Midnight%20Cir/guest_bedroom_1_2_mml1h4.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 1
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819736/1853%20Midnight%20Cir/guest_bedroom_1_3_sivb6q.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 1
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819736/1853%20Midnight%20Cir/guest_bedroom_2_1_jhyusw.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 2
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819734/1853%20Midnight%20Cir/guest_bedroom_2_2_n4zqic.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 2
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819734/1853%20Midnight%20Cir/guest_bedroom_2_3_cywgkt.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 2
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819743/1853%20Midnight%20Cir/guest_bedroom_3_1_qggxzu.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 3
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819743/1853%20Midnight%20Cir/guest_bedroom_3_2_qsdugt.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.GUEST_BEDROOM,
        seq: 3
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819887/1853%20Midnight%20Cir/guest_bathroom_1_1_blja4w.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.GUEST_BATHROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819884/1853%20Midnight%20Cir/guest_bathroom_1_2_xr5cqa.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.GUEST_BATHROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819880/1853%20Midnight%20Cir/guest_bathroom_1_3_izj2qu.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.GUEST_BATHROOM,
    },
    {
        url: "https://res.cloudinary.com/realtoragents-us/image/upload/v1656819884/1853%20Midnight%20Cir/dinning_area_1_1_wsswsy.jpg",
        property: "1853 Midnight Cir",
        roomType: RoomType.DINNING_AREA,
    },
    {
        url: "",
        property: "",
        roomType: RoomType.COMMON_AREA,
    }
]

export default IMAGES